import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const customerStoryGql = `... on blog_customerStory_Entry {
  typeHandle
  title
  navigationTheme
  customerStoryReadMoreButton
  blogCategory(orderBy: "lft asc") {
    id
    level
    title
    uri
    ... on blog_Category {
      breadcrumbLink {
        uri
      }
    }
  }
  customerStoryContent {
    ... on customerStoryContent_basicText_BlockType {
      typeHandle
      textBlocks {
        ... on textBlocks_BlockType {
          eyebrow
          subheader
          text
          textAlignment
          ${linksSuperTableGql()}
          primaryCtaBtn
          primaryCtaBtnLabel
          primaryCtaBtnAriaLabel
          primaryCtaBtnTheme
          primaryCtaBtnOpenInANewWindow
        }
      }
      columns
      colors
    }
    ... on customerStoryContent_header_BlockType {
      typeHandle
      eyebrowText
      pageTitle
      leadParagraph
      detailsTable {
        detailTitle
        detailDescription @markdown
      }
    }
    ... on customerStoryContent_relatedProducts_BlockType {
      typeHandle
      pageTitle
      relatedProduct {
        ... on product_productPage_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcWebp
                srcset
              }
            }
          }
        }
        ... on product_productPage2022_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on customerStoryContent_text_BlockType {
      typeHandle
      text
      header
      sectionDivider
    }
    ... on customerStoryContent_productOverviewGallery_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      displayTitle
      displayThumbnailNav
      galleryAssets {
        ... on images_Asset {
          title
          caption
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
        ... on videos_Asset {
          title
          caption
          videoId
          videoSource
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
      }
    }
    ... on customerStoryContent_blockquote_BlockType {
      typeHandle
      blockQuote {
        ... on blockQuote_BlockType {
          enableQuote
          quote
          quoteAuthor
          descriptor
          workplace
          authorImage {
            ... on images_Asset {
              filterSquare {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on customerStoryContent_eventCards_BlockType {
      typeHandle
      pageTitle
      eventCards {
        ... on eventCards_BlockType {
          eyebrowText
          eventTitle
          eventDescription
          cardStyle
          eventType {
            ... on event_Category {
              title
            }
          }
          eventImage {
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
          }
          primaryButtonAsset {
            url
            kind
          }
          primaryButtonEntry {
            uri
          }
          primaryButtonCustomUrl
          primaryButtonText
          secondaryButtonAsset {
            kind
            url
          }
          secondaryButtonEntry {
            uri
          }
          secondaryButtonCustomUrl
          secondaryButtonText
        }
      }
    }
    ... on customerStoryContent_links_BlockType {
      typeHandle
      linkObject {
        ... on linkObject_BlockType {
          customUrl
          linkLabel
          openInANewWindow
          asset {
            url
            kind
          }
          entry {
            uri
          }
        }
      }
    }
    ... on customerStoryContent_relatedCustomerStories_BlockType {
      typeHandle
      sectionTitle
      customerStories {
        ... on blog_customerStory_Entry {
          cardTitle
          cardDescription
          location
          customerStorySecondaryTitle
          customerStorySecondaryDescription
          uri
        }
      }
    }
    ... on customerStoryContent_promoBlock_BlockType {
      typeHandle
      displayTitle
      customerStoryStyle
      customerStory {
        ... on blog_customerStory_Entry {
          cardTitle
          cardDescription
          location
          customerStorySecondaryTitle
          customerStorySecondaryDescription
          uri
          customerStoryImage {
            ... on images_Asset {
              uncropped1920w {
                srcWebp
                srcset
                src
              }
            }
          }
        }
      }
    }
  }
}`;
