export const heroBanner2023Gql = `... on landingPageContent2023_heroBanner_BlockType{
    typeHandle
    desktopBannerHeight
    desktopBannerAspectRatio
    mobileBannerHeight
    mobileBannerAspectRatio
    indicatorType
    autoplaySlides
    slideDuration
    slide{
      ... on slide_BlockType{
        slideName
        slideIcon {
          ... on svgIcons_Asset{
            title
            url
          }
        }
        contentEyebrow
            contentHeader
            contentDescription
            primaryCtaBtn
            primaryCtaBtnLabel
            primaryCtaBtnAriaLabel
            primaryCtaBtnTheme
            primaryCtaBtnOpenInANewWindow
            secondaryCtaBtn
            secondaryCtaBtnLabel
            secondaryCtaBtnAriaLabel
            secondaryCtaBtnTheme
            secondaryCtaBtnOpenInANewWindow
            backgroundVideo {
              id
                ... on videoUploads_Asset{
                    url
                    size
                    extension
                }
            }
            contentImage {
                id
                ... on images_Asset{
                  title
                  lpImageTextUncropped{
                    src
                    srcset
                  }
                  lpBackgroundWide{
                    src
                    srcset
                  }
                  landingPage11 {
                    src
                    srcset
                  }
                  landingPage169 {
                    srcset
                    src
                  }
                  landingPage1851 {
                    src
                    srcset
                  }
                  landingPage2351 {
                    src
                    srcset
                  }
                  landingPage53 {
                    src
                    srcset
                  }
                  landingPage75 {
                    src
                    srcset
                  }
                }
              }
            contentImageHeightDesktop
            contentPositionDesktop
            contentPositionMobile
            stackTextImageMobile
            colorTheme
            backgroundImageDesktop{
              ... on images_Asset{
                title
                lpImageTextUncropped{
                      src
                      srcset
                  }
                lpBackgroundWide{
                    src
                    srcset
                  }
                landingPage11 {
                  src
                  srcset
                }
                landingPage169 {
                  srcset
                  src
                }
                landingPage1851 {
                  src
                  srcset
                }
                landingPage2351 {
                  src
                  srcset
                }
                landingPage53 {
                  src
                  srcset
                }
                landingPage75 {
                  src
                  srcset
                }
              }
            }
            backgroundImageMobile{
              ... on images_Asset{
                title
                lpImageTextUncropped{
                      src
                      srcset
                  }
                lpBackgroundWide{
                  src
                      srcset
                }
                landingPage11 {
                  src
                  srcset
                }
                landingPage169 {
                  srcset
                  src
                }
                landingPage1851 {
                  src
                  srcset
                }
                landingPage2351 {
                  src
                  srcset
                }
                landingPage53 {
                  src
                  srcset
                }
                landingPage75 {
                  src
                  srcset
                }
                landingPageMobile1185 {
                  src
                  srcset
                }
                landingPageMobile1235 {
                  src
                  srcset
                }
                landingPageMobile35 {
                  src
                  srcset
                }
                landingPageMobile57 {
                  src
                  srcset
                }
                landingPageMobile916 {
                  src
                  srcset
                }
              }
            }
            backgroundOverlayOpacity
            contentBackgroundBoxEnabled
            enableTextShadows
            textShadowOffset
            textShadowBlurStrength
      }
    }
}`;
