import { landingPageContentGql } from '../matrix_blocks/landingPageContentGql.js';
import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

const pageGql = `title
uri
id
... on pressRelease_pressRelease_Entry {
  entryPostDate: postDate @formatDateTime(format: "F d, Y")
  articleSummary,
  pressKit {
    ... on pressKits_Asset {
      filename
      title
      url
      size
    }
  },
  articleAttachments {
    filename,
    url,
    title,
    size
  },
  location,
  pressReleaseCategory {
    ... on pressRelease_Category {
      id
      title
      uri
    }
  },
  relatedProductCategories(orderBy: "lft asc") {
    id
    level
  },
  blogContent {
    ... on blogContent_text_BlockType {
      typeHandle
      header
      text
    }
    ... on blogContent_image_BlockType {
      typeHandle
      caption
      imageType
      imageHeightRestraint
      image {
        title
        ... on blogNews_Asset {
          blogNewsLandscape {
            srcsetWebp
            srcset
          }
          blogNewsSquare {
            srcsetWebp
            srcset
          }
          blogNewsUncropped{
            srcsetWebp
            srcset
          }
        }
      }
    }
    ... on blogContent_blockquote_BlockType {
      typeHandle
      blockquote
      quoteByline
      quoteBylineDetails
    }
    ... on blogContent_links_BlockType {
      typeHandle
      header
      ${linksSuperTableGql('links')}
    }
    ... on blogContent_mediaContacts_BlockType {
      typeHandle
      header
      mediaContacts {
        ... on mediaContact_mediaContact_Entry {
          title
          descriptor: mediaContactDescriptor
          email
          phoneNumber
        }
      }
    }
  }
  ${landingPageContentGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const pressReleaseGql = `query($site: String, $uri: String) {
  entry(
    section: ["pressRelease"]
    site: [$site]
    uri: [$uri]
  ) {
    ${pageGql}
  }
}`;

export const pressReleaseGenerateGql = function(sitesString = `$site`, params = {}) {
  const offset = params.offset || '0';
  const limit = params.limit || 'null';

  return `query {
    entryCount(
      uri:":notempty:"
      section: ["pressRelease"]
      site: [${sitesString}]
      limit: null
    )
    entries(
      uri:":notempty:"
      section: ["pressRelease"]
      site: [${sitesString}]
      offset: ${offset}
      limit: ${limit}
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
