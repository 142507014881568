export const linksMatrixGql = function(index = '', limit = '') {
  return `linksMatrix${index}${limit !== '' ? `(limit: ${limit})` : ''} {
  ... on linksMatrix${index}_entry_BlockType {
    element: entry(limit: 1) {
      title
      uri
      ... on product_contentTab_Entry {
        tabTitle
      }
      ... on product_tableTab_Entry {
        tabTitle
      }
      ... on product_multiColumnTableTab_Entry {
        tabTitle
      }
      ... on product_compareTab_Entry {
        tabTitle
      }
      ... on product_relatedTab_Entry {
        tabTitle
      }
    }
    buttonLabel
    ariaLabel
    openInANewWindow
  }
  ... on linksMatrix${index}_productCategory_BlockType {
    element: productCategory {
      title
      uri
    }
    buttonLabel
    ariaLabel
    openInANewWindow
  }
  ... on linksMatrix${index}_asset_BlockType {
    element: asset(limit: 1) {
      title
      uri: url
    }
    buttonLabel
    ariaLabel
    openInANewWindow
  }
  ... on linksMatrix${index}_custom_BlockType {
    urlText
    buttonLabel
    ariaLabel
    openInANewWindow
  }
}`;
};
