import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const basicTextGql = `... on landingPageContent_basicText_BlockType {
  typeHandle
  textBlocks {
    ... on textBlocks_BlockType {
      eyebrow
      subheader
      text
      textAlignment
      ${linksSuperTableGql()}
      primaryCtaBtn
      primaryCtaBtnLabel
      primaryCtaBtnAriaLabel
      primaryCtaBtnTheme
      primaryCtaBtnOpenInANewWindow
    }
  }
  columns
  colors
}`;
