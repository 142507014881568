import { linksMatrixGql } from '../link_field/linksMatrixGql.js';
import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';
import { productTabCompareGql } from './product_tab/productTabCompareGql.js';
import { productTabContentGql } from './product_tab/productTabContentGql.js';
import { productTabMultiColumnTableGql } from './product_tab/productTabMultiColumnTableGql.js';
import { productTabRelatedGql } from './product_tab/productTabRelatedGql.js';
import { productTabTableGql } from './product_tab/productTabTableGql.js';
import { productTabRelatedResourcesGql } from './product_tab/productTabRelatedResourcesGql.js';

const pageGql = `
title
sectionHandle
siteId
uri
... on product_productPage_Entry {
  typeHandle
  productCategories(orderBy: "lft asc") {
    id
    level
    title
    uri
    ... on productCategories_Category {
      breadcrumbLink {
        uri
      }
    }
  }
  productOverviewSubhead
  productOverviewDescription
  productBrochure {
    ... on productBrochures_Asset {
      title
      url
    }
  }
  ${linksMatrixGql()}
  productOverviewGallery {
    ... on productImages_Asset {
      title
      caption
      galleryThumbnail {
        srcset
        srcsetWebp
      }
      galleryEnlarged {
        srcset
        srcsetWebp
      }
    }
    ... on productVideos_Asset {
      title
      caption
      videoId
      videoSource
      galleryThumbnail {
        srcset
        srcsetWebp
      }
      galleryEnlarged {
        srcset
        srcsetWebp
      }
    }
  }
  relatedProducts {
    typeHandle
    title
    uri
    ... on product_productPage_Entry {
      productFeaturedImage {
        ... on productImages_Asset {
          title
          filterSquare {
            srcset
            srcsetWebp
          }
        }
      }
      relatedEntryDescription
    }
  }
  productDisclaimerFootnote{
    ... on disclaimerFootnote_disclaimerFootnote_Entry{
      content:disclaimercontent
    }
  }
}

... on product_productPage2022_Entry {
  typeHandle
  navigationTheme
  productCategories(orderBy: "lft asc") {
    id
    level
    title
    uri
    ... on productCategories_Category {
      breadcrumbLink {
        uri
      }
    }
  }
  bodyContent2022 {
    ... on bodyContent2022_header_BlockType {
      typeHandle
      productBrand
      productDescription
      productDescriptors
      productModel
      headerCta {
        ... on headerCta_BlockType {
          entry {
            url
          }
          asset {
            url
          }
          styling
          openInANewWindow
          custom
          buttonLabel
          ariaLabel
        }
      }
      gallery {
        ... on images_Asset {
          title
          caption
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
        ... on videos_Asset{
          videoId
          videoSource
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
      }
      sidebar
      sidebarButton {
        ... on sidebarButton_BlockType {
          entry {
            url
          }
          asset {
            url
          }
          openInANewWindow
          custom
          buttonLabel
          ariaLabel
        }
      }
    }
    ... on bodyContent2022_chart_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      chartColumn {
        columnContent @markdown
        columnHeading
      }
    }
    ... on bodyContent2022_productByTheNumbers_BlockType {
      typeHandle
      pageTitle
      pageDescription
      displayInHeaderQuicklinks
      productByTheNumber {
        ... on productByTheNumber_BlockType {
          label
          unit
          description
          icon {
            ... on svgIcons_Asset {
              url
              title
            }
          }
        }
      }
    }
    ... on bodyContent2022_textAndImage_BlockType {
      pageTitle
      displayInHeaderQuicklinks
      image {
        ... on images_Asset {
          url
          title
          lpBackgroundWide {
            src
          }
          lpImageTextUncropped {
            srcWebp
            srcset
          }
        }
        ... on svgIcons_Asset {
          url
          title
        }
      }
      backgroundVerticalPosition
      backgroundOpacity
      description
      typeHandle
      label
      layoutStyle
      ctaButton {
        ... on ctaButton_BlockType {
          asset {
            url
          }
          entry {
            uri
          }
          custom
          buttonStyling
          buttonLabel
          ariaLabel
          openInANewWindow
        }
      }
      colors
    }
    ... on bodyContent2022_videoPlayer_BlockType {
      typeHandle
      colors
      header
      text
      pageTitle
      displayInHeaderQuicklinks
      videoDuration
      buttonText
      image {
        ... on landingPageVideos_Asset {
          id
          title
          videoId
          videoSource
          lpBackgroundWide {
            srcsetWebp
            srcset
          }
        }
        ... on images_Asset {
          title
          videoId
          videoSource
          lpBackgroundWide {
            srcset
            srcWebp
          }
        }
        ... on videos_Asset {
          title
          videoId
          videoSource
          lpBackgroundWide {
            srcset
            srcWebp
          }
        }
      }
      backgroundVideo {
        id
        ... on videoUploads_Asset {
          url
          size
          extension
        }
        ... on videos_Asset {
          url
          size
          extension
        }
      }
    }
    ... on bodyContent2022_quoteSlider_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      slides2 {
        ... on slides2_BlockType {
          enableQuote
          quoteTitle
          quote
          quoteAuthor
          descriptor
          workplace
          authorImage {
            ... on images_Asset {
              id
              filterSquare {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on bodyContent2022_pageHeader_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      leadParagraph
      subtitle
    }
    ... on bodyContent2022_textContentCards_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      roundedCorners
      moviePosters
      cardAssets {
        ... on images_Asset {
          cardDescription
          cardTitle
          title
          altText
          lpImageTextUncropped {
            srcWebp
            srcset
          }
          ${linksMatrixGql()}
        }
      }
    }
    ... on bodyContent2022_productOverviewGallery_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      displayTitle
      displayThumbnailNav
      galleryAssets {
        ... on images_Asset {
          title
          caption
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
        ... on videos_Asset {
          title
          caption
          videoId
          videoSource
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
      }
    }
    ... on bodyContent2022_videoGallery_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      videos {
        ... on videos_Asset {
          videoId
          videoSource
          videoType
          videoDescription
          videoDuration
          galleryEnlarged {
            srcWebp
            srcset
          }
          galleryThumbnail {
            srcWebp
            srcset
          }
        }
      }
    }
    ... on bodyContent2022_relatedResources_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      displayFilter
      relatedResource {
        ... on pdfs_Asset {
          ${productTabRelatedResourcesGql}
        }
        ... on videos_Asset {
          ${productTabRelatedResourcesGql}
        }
        ... on images_Asset {
          ${productTabRelatedResourcesGql}
        }
        ... on zipFiles_Asset {
          ${productTabRelatedResourcesGql}
        }
      }
    }
    ... on bodyContent2022_relatedProducts_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      relatedProduct {
        ... on product_productPage2022_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcset
                srcWebp
              }
            }
          }
        }
        ... on product_productPage_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on bodyContent2022_basicText_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      textBlocks {
        ... on textBlocks_BlockType {
          eyebrow
          subheader
          text
          textAlignment
          ${linksSuperTableGql()}
          primaryCtaBtn
          primaryCtaBtnLabel
          primaryCtaBtnAriaLabel
          primaryCtaBtnTheme
          primaryCtaBtnOpenInANewWindow
        }
      }
      columns
      colors
    }
    ... on bodyContent2022_featuredContent_BlockType {
      typeHandle
      pageTitle
      header
      displayInHeaderQuicklinks
      displayArticleSummary
      backgroundColor
      featuredContent {
        postDate
        title
        typeHandle
        ... on blog_blog_Entry {
          uri
          relatedEntryDescription
          blogFeaturedImage {
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
            ... on blogNews_Asset {
              filterPageFeaturedImage {
                srcWebp
                srcset
              }
            }
          }
          blogContent {
            ... on blogContent_text_BlockType {
              text
            }
          }
        }
        ... on pressRelease_pressRelease_Entry {
          uri
          relatedEntryDescription
          blogContent {
            ... on blogContent_text_BlockType {
              text
            }
          }
          blogFeaturedImage {
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
            ... on blogNews_Asset {
              filterPageFeaturedImage {
                srcWebp
                srcset
              }
            }
          }
        }
        ... on event_event_Entry {
          uri
          eventStartDateTime
          relatedEntryDescription
          eventFeaturedImage {
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
            ... on event_Asset {
              filterPageFeaturedImage {
                srcWebp
                srcset
              }
            }
          }
          eventsCategory {
            slug
          }
        }
        ... on event_eventV2_Entry {
          uri
          eventStartDateTime
          relatedEntryDescription
          eventOverviewLinks: ${linksMatrixGql('', 2)}
          eventOverviewImage {
            ... on images_Asset {
              filterSquare {
                srcWebp
                srcset
              }
            }
          }
          eventsCategory {
            slug
          }
        }
        ... on product_productPage_Entry {
          uri
          relatedEntryDescription
          productFeaturedImage {
            ... on productImages_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
          }
        }
        ... on product_productPage2022_Entry {
          uri
          productFeaturedImage {
            ... on images_Asset {
              filterLandscape {
                srcWebp
                srcset
              }
            }
          }
          bodyContent2022 {
            ... on bodyContent2022_header_BlockType {
              productDescription
            }
          }
        }
        ... on landingPage_landingPage_Entry {
          uri
          relatedEntryDescription
          landingPageFeaturedImage {
            ... on landingPageImages_Asset {
              lpImageGridLandscape {
                srcWebp
                srcset
              }
            }
          }

        }
      }
    }
  }
}

children(
  uri:":notempty:"
  limit: 6
) {
  id
  title
  typeHandle
  slug
  uri
  ${productTabCompareGql}
  ${productTabContentGql}
  ${productTabMultiColumnTableGql}
  ${productTabRelatedGql}
  ${productTabTableGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const productGql = `query($fallbackUri: String, $site: String, $uri: String) {
  entry(
    section: ["product"]
    type: ["productPage", "productPage2022"]
    site: [$site]
    uri: [$uri]
    level: 1
  ) {
    ${pageGql}
  }
  fallbackEntry: entry(
    section: ["product"]
    type: ["productPage", "productPage2022"]
    site: [$site]
    uri: [$fallbackUri]
    level: 1
  ) {
    ${pageGql}
  }
}`;

export const productGenerateGql = function (sitesString = `$site`, params = {}) {
  const offset = params.offset || '0';
  const limit = params.limit || 'null';

  return `query {
    entryCount(
      uri:":notempty:"
      section: ["product"]
      type: ["productPage", "productPage2022"]
      site: [${sitesString}]
      limit: null
      level: 1
    )
    entries(
      uri:":notempty:"
      section: ["product"]
      type: ["productPage", "productPage2022"]
      site: [${sitesString}]
      offset: ${offset}
      limit: ${limit}
    ) {
      ${pageGql}
    }
  }`;
};
