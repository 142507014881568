export const productHeader2023Gql = `... on landingPageContent2023_productHeader_BlockType {
  typeHandle
  productBrand
  productDescription
  productDescriptors
  productModel
  headerCta {
    ... on headerCta_BlockType {
      entry {
        url
      }
      asset {
        url
      }
      styling
      openInANewWindow
      custom
      buttonLabel
      ariaLabel
    }
  }
  gallery {
    ... on images_Asset {
      title
      caption
      galleryThumbnail {
        srcset
        srcWebp
      }
      galleryEnlarged {
        srcset
        srcWebp
      }
    }
    ... on videos_Asset{
      videoId
      videoSource
      galleryThumbnail {
        srcset
        srcWebp
      }
      galleryEnlarged {
        srcset
        srcWebp
      }
    }
  }
  sidebar
  sidebarButton {
    ... on sidebarButton_BlockType {
      entry {
        url
      }
      asset {
        url
      }
      openInANewWindow
      custom
      buttonLabel
      ariaLabel
    }
  }
}`;