export const heroBannerGql = `
... on landingPageContent_heroBanner_BlockType{
  	typeHandle
  	desktopBannerHeight
    desktopBannerAspectRatio
    mobileBannerHeight
   	mobileBannerAspectRatio
    indicatorType
    autoplaySlides
    slideDuration
    slide{
    	... on slide_BlockType{
    		slideName
    		slideIcon {
    			... on svgIcons_Asset{
    				title
    				url
    			}
    		}
    		contentEyebrow
            contentHeader
            contentDescription
            primaryCtaBtn
            primaryCtaBtnLabel
            primaryCtaBtnAriaLabel
            primaryCtaBtnTheme
            primaryCtaBtnOpenInANewWindow
            secondaryCtaBtn
            secondaryCtaBtnLabel
            secondaryCtaBtnAriaLabel
            secondaryCtaBtnTheme
            secondaryCtaBtnOpenInANewWindow
            backgroundVideo {
            	id
            		... on videoUploads_Asset{
              			url
              			size
              			extension
            		}
          	}
            contentImage {
                id
                ... on landingPageImages_Asset{
	                title
	                lpImageTextUncropped{
		                src
		                srcset
	                }
	                lpBackgroundWide{
		                src
		                srcset
	                }
                }
              }
            contentImageHeightDesktop
            contentPositionDesktop
            contentPositionMobile
			stackTextImageMobile
            colorTheme
            backgroundImageDesktop{
            	... on landingPageImages_Asset{
            		title
	            	lpImageTextUncropped{
	                    src
	                    srcset
	                }
            		lpBackgroundWide{
	                	src
	                	srcset
	                }
            	}
            }
            backgroundImageMobile{
            	... on landingPageImages_Asset{
            		title
            		lpImageTextUncropped{
	                    src
	                    srcset
	                }
            		lpBackgroundWide{
            			src
                    	srcset
            		}
            	}
            }
            backgroundOverlayOpacity
            contentBackgroundBoxEnabled
            enableTextShadows
            textShadowOffset
            textShadowBlurStrength
    	}
    }
}`;
