export const lpPromoBlock2023Gql = `... on landingPageContent2023_lpPromoBlock_BlockType {
  typeHandle
  promoBlocks {
    ... on promoBlocks_BlockType {
      eyebrowText
      promoTitle
      promoDescription
      cardStyle
      promoType
      imageStyle
      promoImage {
        ... on images_Asset {
          filterLandscape {
            srcWebp
            srcset
          }
          lpGalleryPortrait {
            optimizedImageUrls
            srcset
            srcWebp
          }
          lpImageTextUncropped {
            srcWebp
            srcset
          }
        }
      }
      primaryButtonAsset {
        url
        kind
      }
      primaryButtonEntry {
        uri
      }
      primaryButtonCustomUrl
      primaryButtonText
      secondaryButtonAsset {
        kind
        url
      }
      secondaryButtonEntry {
        uri
      }
      secondaryButtonCustomUrl
      secondaryButtonText
    }
  }
}`;
