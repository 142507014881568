import { landingPageContentGql } from '../matrix_blocks/landingPageContentGql.js';
import { linksMatrixGql } from '../link_field/linksMatrixGql.js';
import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

const pageGql = `title
uri
...on event_eventV2_Entry {
    relatedProductCategories(orderBy: "lft asc") {
        id
        level
    }
    eventsCategory{
        ... on event_Category{
            title
            uri
            breadcrumbLink {
                uri
            }
        }
    }
    eventOverviewImage {
      title
      ... on event_Asset {
        square: filterSquare {
          srcset
          srcsetWebp
        }
        landscape: relatedEntries {
          srcset
          srcsetWebp
        }
      }
    }
    eventOverviewDescription
    eventStartDateTime: eventStartDateTime @formatDateTime (format: "D M d Y H:i:s O")
    eventEndDateTime: eventEndDateTime @formatDateTime (format: "D M d Y H:i:s O")
    eventTimezone: eventStartDateTime @formatDateTime (format: "e")
    dateAndTimeDisplay
    timezone
    eventLocationCityState
    eventPrice
    eventOverviewLinks: ${linksMatrixGql('', 2)}
}
...on event_event_Entry {
  relatedProductCategories(orderBy: "lft asc") {
    id
    level
  }
  eventOverviewGallery {
    ... on event_Asset {
      title
      caption
      galleryThumbnail {
        srcset
        srcsetWebp
      }
    }
  }
  eventOverviewDescription
  eventStartDateTime: eventStartDateTime @formatDateTime (format: "D M d Y H:i:s O")
  eventEndDateTime: eventEndDateTime @formatDateTime (format: "D M d Y H:i:s O")
  eventTimezone: eventStartDateTime @formatDateTime (format: "e")
  dateAndTimeDisplay
  timezone
  eventLocationCityState
  eventPrice
  eventOverviewLinks: ${linksMatrixGql('', 2)}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const eventGql = `query($site: String, $uri: String) {
  entry(
    section: ["event"]
    site: [$site]
    uri: [$uri]
  ) {
    ${pageGql}
  }
}`;

export const eventGenerateGql = function(sitesString = `$site`, params = {}) {
  const offset = params.offset || '0';
  const limit = params.limit || 'null';

  return `query {
    entryCount(
      uri:":notempty:"
      section: ["event"]
      site: [${sitesString}]
      limit: null
    )
    entries(
      uri:":notempty:"
      section: ["event"]
      site: [${sitesString}]
      offset: ${offset}
      limit: ${limit}
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
