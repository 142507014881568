import { landingPageContentGql } from '../matrix_blocks/landingPageContentGql.js';

const pageGql = `title
... on contentSectionHomepage_contentSectionHomepage_Entry{
  ${landingPageContentGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const contentSectionHomepageGql = `query($site: String, $uri: String) {
  entry(
    section: ["contentSectionHomepage"]
    site: [$site]
    uri: [$uri]
  ) {
    ${pageGql}
  }
}`;

export const contentSectionHomepageGenerateGql = function(sitesString = `$site`) {
  return `query {
    entries(
      uri:":notempty:"
      section: ["contentSectionHomepage"]
      site: [${sitesString}]
      limit: null
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
