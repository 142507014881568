import { linksSuperTableGql } from '../link_field/linksSuperTableGql.js';

export const basicText2023Gql = `... on landingPageContent2023_basicText_BlockType {
  typeHandle
  textBlocks {
    ... on textBlocks_BlockType {
      eyebrow
      subheader
      text
      textAlignment
      ${linksSuperTableGql()}
      primaryCtaBtn
      primaryCtaBtnLabel
      primaryCtaBtnAriaLabel
      primaryCtaBtnTheme
      primaryCtaBtnOpenInANewWindow
    }
  }
  columns
  colors
}`;
