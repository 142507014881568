/* eslint-disable */
export default function ({ route, redirect }) {
  // Check if the URL has a fragment (#details)
  const hasFragment = route.hash !== '';
  const hasQueryString = route.fullPath.includes('?');

  if (hasFragment || hasQueryString) {
    return;
  }

  // Apply trailing slash redirection only if no fragment is present
  if (route.path.slice(-1) !== '/') {
    redirect(301, route.path + '/');
  }
}