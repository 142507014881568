import axios from 'axios';
import { addHours } from 'date-fns';
import { blogGenerateGql } from './gql/pages/blogGql.js';
import { blogFilterGenerateQuery } from './gql/pages/filter_pages/blogFilterGql.js';
import { contentSectionHomepageGenerateGql } from './gql/pages/contentSectionHomepageGql.js';
import { eventGenerateGql } from './gql/pages/eventGql.js';
import { eventFilterGenerateQuery } from './gql/pages/filter_pages/eventFilterGql.js';
import { landingPageGenerateGql } from './gql/pages/landingPageGql.js';
import { pressReleaseGenerateGql } from './gql/pages/pressReleaseGql.js';
import { pressReleaseFilterGenerateQuery } from './gql/pages/filter_pages/pressReleaseFilterGql.js';
import { productGenerateGql } from './gql/pages/productGql.js';
import { productFilterGenerateQuery } from './gql/pages/filter_pages/productFilterGql.js';

export const locale = {
  2: 'en',
  5: 'zh',
  6: 'ko',
  7: 'ja',
};
export const sites = `"default", "japanese", "korean", "chinese"`;

export function getPayloadForSection(handle, params = {}) {
  let query = ``;
  let recentDate = null;
  let setRoute = null;

  console.log(`Getting routes for: ${handle}`);

  switch (handle) {
    // case 'home':
    //   query = homeGenerateGql(sites);
    //   setRoute = (data) => {
    //     return data.entries.map((element) => {
    //       const localeSegment = locale[element.siteId];
    //       return {
    //         route: `/${localeSegment}`,
    //         payload: {
    //           entry: element,
    //           articleGlobals: data.articleGlobals,
    //           relatedEntries: data.relatedEntries,
    //           ...getGlobalsForElement(globals, element),
    //         },
    //       };
    //     });
    //   };
    //   break;
    case 'incremental':
      recentDate = addHours(new Date(), -1);
      recentDate = recentDate.toISOString();
      // eslint-disable-next-line no-console
      console.log('Getting content since', recentDate);

      query = `query {
        categoryCount(
          dateUpdated:"> ${recentDate}"
          group: ["blog", "event", "pressRelease", "productCategories"]
          site: [${sites}]
          limit: null
          uri:":notempty:"
        )
        categories(
          dateUpdated:"> ${recentDate}"
          group: ["blog", "event", "pressRelease", "productCategories"]
          site: [${sites}]
          uri:":notempty:"
        ) {
          id
          siteId
          uri
        }
        entryCount(
          dateUpdated:"> ${recentDate}"
          section: ["blog", "contentSectionHomepage", "event", "landingPage", "pressRelease", "product"]
          site: [${sites}]
          limit: null
          uri:":notempty:"
        )
        entries(
          dateUpdated:"> ${recentDate}"
          section: ["blog", "contentSectionHomepage", "event", "landingPage", "pressRelease", "product"]
          site: [${sites}]
          uri:":notempty:"
        ) {
          id
          siteId
          uri
        }
      }`;
      setRoute = (data) => {
        if (data.categoryCount) {
          // eslint-disable-next-line no-console
          console.log(`Total categories: ${data.entryCount}`);
        }
        if (data.entryCount) {
          // eslint-disable-next-line no-console
          console.log(`Total entries: ${data.entryCount}`);
        }
        const uris = [];
        if (data.categories?.length) {
          uris.push(
            ...data.categories.map((element) => {
              const localeSegment = locale[element.siteId];
              return {
                elementId: element.id,
                route: `/${localeSegment}/${element.uri}`,
              };
            })
          );
        }
        if (data.entries?.length) {
          uris.push(
            ...data.entries.map((element) => {
              const localeSegment = locale[element.siteId];
              return {
                elementId: element.id,
                route: `/${localeSegment}/${element.uri}`,
              };
            })
          );
        }

        return uris;
      };
      break;
    case 'relatedTo':
      // eslint-disable-next-line no-console
      console.log('Getting content related to IDs', params.ids);

      query = `query {
        categoryCount(
          relatedTo: [${params.ids}]
          group: ["blog", "event", "pressRelease", "productCategories"]
          site: [${sites}]
          limit: null
          uri:":notempty:"
        )
        categories(
          relatedTo: [${params.ids}]
          group: ["blog", "event", "pressRelease", "productCategories"]
          site: [${sites}]
          uri:":notempty:"
        ) {
          id
          siteId
          uri
        }
        entryCount(
          limit: null
          relatedTo: [${params.ids}]
          section: ["blog", "contentSectionHomepage", "event", "landingPage", "pressRelease", "product"]
          site: [${sites}]
          uri:":notempty:"
        )
        entries(
          relatedTo: [${params.ids}]
          section: ["blog", "contentSectionHomepage", "event", "landingPage", "pressRelease", "product"]
          site: [${sites}]
          uri:":notempty:"
        ) {
          id
          siteId
          uri
        }
      }`;
      setRoute = (data) => {
        if (data.categoryCount) {
          // eslint-disable-next-line no-console
          console.log(`Total categories: ${data.entryCount}`);
        }
        if (data.entryCount) {
          // eslint-disable-next-line no-console
          console.log(`Total entries: ${data.entryCount}`);
        }
        const uris = [];
        if (data.categories?.length) {
          uris.push(
            ...data.categories.map((element) => {
              const localeSegment = locale[element.siteId];
              return {
                route: `/${localeSegment}/${element.uri}`,
              };
            })
          );
        }
        if (data.entries?.length) {
          uris.push(
            ...data.entries.map((element) => {
              const localeSegment = locale[element.siteId];
              return {
                route: `/${localeSegment}/${element.uri}`,
              };
            })
          );
        }

        return uris;
      };
      break;
    case 'blog':
      query = blogGenerateGql(sites, params);
      setRoute = (data) => {
        if (data.entryCount) {
          // eslint-disable-next-line no-console
          console.log(`Total blog posts: ${data.entryCount}`);
        }
        return data.entries.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
            },
          };
        });
      };
      break;
    case 'blogFilter':
      query = blogFilterGenerateQuery(sites);
      setRoute = (data) => {
        return data.categories.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              category: element,
            },
          };
        });
      };
      break;
    case 'contentSectionHomepage':
      query = contentSectionHomepageGenerateGql(sites);
      setRoute = (data) => {
        return data.entries.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
              articleGlobals: data.articleGlobals,
              relatedEntries: data.relatedEntries,
            },
          };
        });
      };
      break;
    case 'event':
      query = eventGenerateGql(sites, params);
      setRoute = (data) => {
        if (data.entryCount) {
          console.log(`Total events: ${data.entryCount}`);
        }
        return data.entries.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
            },
          };
        });
      };
      break;
    case 'eventFilter':
      query = eventFilterGenerateQuery(sites);
      setRoute = (data) => {
        return data.categories.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              category: element,
            },
          };
        });
      };
      break;
    case 'landingPages':
      query = landingPageGenerateGql(sites, params);
      setRoute = (data) => {
        if (data.entryCount) {
          console.log(`Total landing pages: ${data.entryCount}`);
        }
        return data.entries.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
              articleGlobals: data.articleGlobals,
              relatedEntries: data.relatedEntries,
            },
          };
        });
      };
      break;
    case 'pressRelease':
      query = pressReleaseGenerateGql(sites, params);
      setRoute = (data) => {
        if (data.entryCount) {
          console.log(`Total press releases: ${data.entryCount}`);
        }
        return data.entries.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
            },
          };
        });
      };
      break;
    case 'pressReleaseFilter':
      query = pressReleaseFilterGenerateQuery(sites);
      setRoute = (data) => {
        return data.categories.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              category: element,
            },
          };
        });
      };
      break;
    case 'product':
      query = productGenerateGql(sites, params);
      setRoute = (data) => {
        if (data.entryCount) {
          console.log(`Total products: ${data.entryCount}`);
        }
        const uris = [];
        data.entries.forEach((element) => {
          const localeSegment = locale[element.siteId];
          uris.push({
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              entry: element,
            },
          });
          if (element.children?.length) {
            element.children.forEach((child) => {
              uris.push({
                route: `/${localeSegment}/${child.uri}`,
                payload: {
                  entry: element,
                },
              });
            });
          }
        });
        // eslint-disable-next-line no-console
        return uris;
      };
      break;
    case 'productFilter':
      query = productFilterGenerateQuery(sites);
      setRoute = (data) => {
        return data.categories.map((element) => {
          const localeSegment = locale[element.siteId];
          return {
            route: `/${localeSegment}/${element.uri}`,
            payload: {
              category: element,
            },
          };
        });
      };
      break;
  }

  return axios
    .post(
      process.env.CRAFT_API_URL,
      {
        query,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.CRAFT_AUTH_TOKEN}`,
        },
      }
    )
    .then((res) => {
      if (res.data?.data) {
        return setRoute(res.data.data);
      } else {
        console.log(res.data);
        console.log(res.data.errors[0].locations);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
