export const quoteSlider2023Gql = `... on landingPageContent2023_quoteSlider_BlockType {
  typeHandle
  pageTitle
  displayInHeaderQuicklinks
  slides2 {
    ... on slides2_BlockType {
      enableQuote
      quoteTitle
      quote
      quoteAuthor
      descriptor
      workplace
      authorImage {
        ... on images_Asset {
          id
          filterSquare {
            srcWebp
            srcset
          }
        }
      }
    }
  }
}`;
